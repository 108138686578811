import { CHILD_SERVICE_TYPES, theme } from "@project/shared"
import { Skeleton } from "antd"
import { t } from "i18next"
import React from "react"
import styled from "styled-components"
import wareki from "wareki"
import { StrPadd } from "../../../utils"

const DateComp = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
  @media print {
    font-size: 10px;
    margin-top: 5px;
    margin-left: 5px;
  }
`

const ServiceComp = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  @media print {
    font-size: 12px;
    margin-bottom: 0px;
  }
`

const Wrapper = styled.div`
  &.top-comp-table {
    overflow-x: auto;

    & * {
      border-color: ${theme.black} !important;
    }
  }
  margin-bottom: 10px;
  @media print {
    overflow: visible !important;
    margin-bottom: 0px;
  }
`
const Row1 = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 2fr 2fr 3fr;
  border: 1px solid #d2d1d1;
  border-right: none;
  min-width: 1200px;

  border-bottom: none;
  @media print {
    min-width: 100%;
    border-top: 1px solid black;
    border-bottom: none;
    border-right: 1px solid ${theme.black};
    grid-template-columns: 2fr 3fr 4fr 3fr 2fr 3fr;
  }
`
const Row2 = styled(Row1)`
  grid-template-columns: 2fr 8fr 2fr 3fr;
  min-width: 1200px;

  border-bottom: 1px solid black;
  @media print {
    min-width: 100%;
    border-top: none;
    border-bottom: 1px solid ${theme.black};
    border-right: 1px solid ${theme.black};
    grid-template-columns: 2fr 10fr 2fr 3fr;
  }
`
const Title = styled.div`
  background-color: #f4f4f4;
  border-right: 1px solid #d2d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  white-space: break-spaces;
  font-size: 12px;
  @media print {
    font-size: 10px;
    background-color: transparent;
    border: 1px solid ${theme.black};
    padding: 0px;
  }
`
const Answer = styled.div<{ borderNone?: boolean }>`
  border: none;
  border-right: ${(props) => (props.borderNone ? "none" : "1px solid #d2d1d1")};
  font-size: 12px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  @media print {
    font-size: 10px;
    border-bottom: 0.5px solid ${theme.black};
  }
  .bordered-number {
    border-right: 1px solid #d2d1d1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media print {
      font-size: 10px;
      border-right: 2px solid ${theme.black} !important;
      border-top: 0.5px solid ${theme.black} !important;
      border-bottom: none;
    }
    .fa-num:nth-child(1) {
      border: 1px solid #34d;
    }
  }
  .bordered-number:first-child {
    @media print {
      border-left: 1px solid ${theme.black} !important;
    }
  }
  .bordered-number:last-child {
    @media print {
      border-right: 1px solid ${theme.black} !important;
    }
  }
`
const TextAnswer = styled.div`
  border-right: 1px solid #d2d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  @media print {
    font-size: 10px;
    border: 1px solid ${theme.black};
  }
`
const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ServiceProvisionTopComponent: React.FC<{
  year?: number
  month?: number
  data?: any
  contractAmount?: number
  isLoadingInfo?: boolean
  businessNumber?: string
  formNumber?: string
  isChangeDisplayDisableChild?: boolean
  isSinglePrint?: boolean
}> = ({
  data,
  isLoadingInfo,
  year,
  month,
  formNumber,
  isChangeDisplayDisableChild,
  isSinglePrint,
}) => {
  const certificateNumber =
    data?.child_receiving_certificates?.[0]?.receiving_certificate_number || "0"
  const officeNumber =
    data?.active_contract_facility?.facility?.business_number || "0"
  const _office_number = StrPadd(officeNumber)
  const era =
    `${wareki(`${year}-${month?.toString()?.padStart(2, "0")}`, {
      unit: true,
    })}` +
    month?.toString()?.padStart(2, "0") +
    "月分"
  if (isLoadingInfo) {
    return (
      <>
        <ServiceComp>
          {
            CHILD_SERVICE_TYPES[
              data?.child_receiving_certificates?.[0]?.service_type
            ]
          }
        </ServiceComp>
        <TopWrapper>
          <DateComp>{era}</DateComp>
          {formNumber && <DateComp>{formNumber}</DateComp>}
        </TopWrapper>
        <Wrapper>
          <Skeleton />
        </Wrapper>
      </>
    )
  }

  return (
    <>
      {!isSinglePrint && (
        <ServiceComp>
          {data?.child_receiving_certificates?.[0]?.service_type === 1
            ? t("After-school day service provision record sheet")
            : t("Child development support provision record sheet")}
        </ServiceComp>
      )}

      <TopWrapper>
        <DateComp>{era}</DateComp>
        {formNumber && <DateComp>{formNumber}</DateComp>}
      </TopWrapper>
      <Wrapper className={"top-comp-table"}>
        <Row1 className={"row-1"}>
          <Title>{t("Recipient number")}</Title>
          <Answer borderNone>
            {certificateNumber
              .padStart(10, "0")
              .split("")
              .map((item, index) => (
                <div key={index} className={"bordered-number"}>
                  {item}
                </div>
              ))}
          </Answer>
          <Title>
            {isChangeDisplayDisableChild
              ? t(
                  "Name of guardian for whom benefits were determined\n (Child's name)"
                )
              : t(
                  "Name of guardian for whom benefits were determined\n (name of disable child)"
                )}
          </Title>
          <TextAnswer style={{ padding: "0px 2px" }}>
            {data?.Parent?.parent_name || "-"}
            <br />
            {`(${data?.child_name || "-"})`}
          </TextAnswer>
          <Title>{t("Office Number")}</Title>
          <Answer borderNone>
            {_office_number.map((item, index) => {
              return (
                <div key={index} className={"bordered-number"}>
                  {item}
                </div>
              )
            })}
          </Answer>
        </Row1>
        <Row2 className={"row-2"}>
          <Title>{t("Contract amount")}</Title>
          <TextAnswer>
            {data?.contact_benefit_days
              ? `${data?.contact_benefit_days}日`
              : "-"}
          </TextAnswer>
          <Title className={"border-left"}>{t("事業者及びその事業所")}</Title>
          <TextAnswer>
            {data?.active_contract_facility?.facility?.facility_name}
          </TextAnswer>
        </Row2>
      </Wrapper>
    </>
  )
}

export { ServiceProvisionTopComponent }
