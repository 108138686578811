import React, { useContext, useEffect, useMemo, useState } from "react"
import { Typography } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { RightOutlined } from "@ant-design/icons"
import {
  PageCard,
  removeBlankAttributes,
  SelectInput,
  theme,
} from "@project/shared"
import { OwnerButton } from "../../atoms"
import { AuthContext, getDateString } from "../../../utils"
import { CalenderTable, DatePicker } from "../../molecules"
import { useUpdateSearchParams } from "../../../utils/useUpdateSearchParams"
import styled from "styled-components"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { getAdditionItemManagementList } from "../../../services/additionItemManagementFacility"
import { fetchBusinessDayRegistration } from "../../../services"
import { hasPermissionForFacility } from "../../../utils/SidebarUtils"
import { AdditionalItemFacilityPermission } from "../../../utils/PermissionKeys"

const Wrapper = styled.div`
  .options__wrapper {
    padding: 36px 0;
    .options__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media screen and (max-width: 420px) {
        display: grid;
        gap: 1rem;
      }
      .options {
        display: flex;
        gap: 1rem;
        align-items: center;
        &--text {
          display: flex;
          align-items: center;
          min-width: 6.5rem;
          svg {
            margin-right: 0.5rem;
            color: ${theme.gray2};
            font-size: 14px;
            font-weight: bold;
          }
        }

        .year__month--selector {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 1rem;
          @media screen and (max-width: 420px) {
            display: grid;
            gap: 1rem;
          }
          .year__selector,
          .month__selector {
            display: flex;
            gap: 0.3rem;
            align-items: center;
            .ant-select-selector {
              width: 80px;
            }
          }
        }

        @media screen and (max-width: 420px) {
          display: grid;
          gap: 20px;

          &--selector {
            width: 100%;
            .dynamic-dropdown-width {
              max-width: 200px;
            }
          }
        }
      }
      @media screen and (max-width: ${theme.breakpoints.md}) {
        .options {
          flex-direction: column;
          align-items: flex-start;
          .year__month--selector {
            /* flex-direction: column; */
            align-items: flex-start;
          }
        }
      }
    }
  }
`
const LOCAL_STORAGE_ADDITIONAL_ITEMS_KEY = "additional_items_filters"

const AdditionalItemFacilityForm = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    facility: queryFacility,
    year: queryYear,
    month: queryMonth,
  } = router.query

  const {
    facilities: permissibleFacilities,
    isOwner,
    permissions,
  } = useContext(AuthContext)

  const facilities = useMemo(
    () =>
      permissibleFacilities?.map((val) => ({
        label: val?.facility_name_short,
        value: val?.id,
      })),
    [permissibleFacilities]
  )

  const localStorageFilters = localStorage.getItem(
    LOCAL_STORAGE_ADDITIONAL_ITEMS_KEY
  )

  let localStorageFiltersObject: any = {}

  try {
    localStorageFiltersObject = localStorageFilters
      ? JSON.parse(localStorageFilters)
      : {}
  } catch (e) {
    localStorageFiltersObject = {}
  }
  const year = queryYear || localStorageFiltersObject?.year?.toString()
  const month = queryMonth || localStorageFiltersObject?.month?.toString()
  const facility =
    queryFacility || localStorageFiltersObject?.currentFacility?.value
  const [updateParams] = useUpdateSearchParams()
  const [searchParams, setsearchParams] = useState<any>({
    facility: localStorageFiltersObject?.facility || facilities?.[0]?.value,
    year: year ? moment(year).format("YYYY") : moment().format("YYYY"),
    month: month ? moment(month).format("MM") : moment().month() + 1,
  })

  useEffect(() => {
    const hasCurrentFacilityInList = facilities?.find(
      (fac) => fac?.value == searchParams?.facility
    )
    if (!hasCurrentFacilityInList) {
      setsearchParams({ ...searchParams, facility: facilities?.[0]?.value })
      updateParams(
        { ...searchParams, facility: facilities?.[0]?.value },
        "/additional-item-management-facility"
      )
    }
  }, [facilities])

  const hasEditAccess = useMemo(
    () =>
      isOwner ||
      hasPermissionForFacility(
        permissions,
        AdditionalItemFacilityPermission,
        "write",
        facility ? facility?.toString() : searchParams?.facility
      ),
    [isOwner, permissions, facility]
  )

  const { data: businessDayHolidays, isLoading: busnissDayLoading } = useQuery(
    ["businessDayRegistrationData", facility, year, month],
    async () => {
      return fetchBusinessDayRegistration({
        facilityId: searchParams.facility,
        currentYear: searchParams.year,
        currentMonth: searchParams.month,
      })
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      select: ({ data }) => {
        const businessHolidays = data?.business_days
          ?.map((data) => {
            if (data?.holiday == "1") {
              return data.day
            }
          })
          .filter(Boolean)
        return businessHolidays
      },
    }
  )

  const { data: additionalSettingsData, isLoading: additionalSettingsLoading } =
    useQuery({
      queryKey: ["additional-item-management-facility", facility, year, month],
      queryFn: () =>
        getAdditionItemManagementList(
          searchParams?.facility,
          searchParams?.year,
          searchParams?.month
        ),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 1,
      cacheTime: 0,
    })

  const groupedObj = additionalSettingsData?.data.reduce((result, obj) => {
    const date = moment(obj.date).format("YYYY-MM-DD")
    const serviceType = obj.service_type

    if (!result[date]) {
      result[date] = {}
    }

    if (!result[date][serviceType]) {
      result[date][serviceType] = []
    }

    result[date][serviceType].push(obj)

    return result
  }, {})

  const handleEditOnClick = ({ date }) => {
    const formatedDate = moment(date).format("YYYY-MM-DD")
    router.push(
      `/additional-item-management/facility/${searchParams?.facility}?date=${formatedDate}`
    )
  }

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_ADDITIONAL_ITEMS_KEY,
      JSON.stringify(searchParams)
    )
  }, [searchParams])

  return (
    <Wrapper>
      <div className={"options__wrapper"}>
        <PageCard title={t("Operation options")}>
          <div className={"options__content"}>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Facility")}</Typography.Text>
              </div>
              <div className={"options--selector"}>
                <SelectInput
                  placeholder={t("All")}
                  className={"dynamic-dropdown-width min-w-150"}
                  dropdownMatchSelectWidth={false}
                  width={"auto"}
                  height={40}
                  options={facilities}
                  value={searchParams?.facility}
                  onChange={(val) => {
                    setsearchParams((prev) => ({
                      ...prev,
                      facility: val,
                    }))
                  }}
                />
              </div>
            </div>
            <div className={"options"}>
              <div className={"options--text"}>
                <RightOutlined />
                <Typography.Text>{t("Year month")}</Typography.Text>
              </div>
              <div className={"year__month--selector"}>
                <DatePicker
                  date={
                    moment({
                      year: +searchParams?.year,
                      month: +searchParams?.month - 1,
                    })?.isValid()
                      ? moment({
                          year: +searchParams?.year,
                          month: +searchParams?.month - 1,
                        })
                      : null
                  }
                  style={{
                    height: "40px",
                  }}
                  picker={"month"}
                  format={"YYYY年MM月"}
                  showArrow={true}
                  disabledDate={(current) => {
                    return (
                      (current && current < moment("2021/01/01")) ||
                      (current && current > moment().add(6, "year"))
                    )
                  }}
                  onDateChange={(value) => {
                    setsearchParams((prev) => ({
                      ...prev,
                      month: moment(value).format("M"),
                      year: moment(value).format("YYYY"),
                    }))
                  }}
                />

                <OwnerButton
                  text={t("Update display ")}
                  typeOf={"secondary"}
                  isLoading={busnissDayLoading || additionalSettingsLoading}
                  onClick={() => {
                    const queries = removeBlankAttributes(searchParams)
                    updateParams(
                      queries,
                      "/additional-item-management-facility"
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </PageCard>
      </div>
      <PageCard
        title={t(`{{facilityName}} {{date}}${t("'s daily addition items")}`, {
          facilityName: facilities?.find((val) => val?.value == facility)
            ?.label,
          date: getDateString(searchParams?.year, searchParams?.month),
        })}
      >
        <CalenderTable
          year={+searchParams?.year}
          month={+searchParams?.month}
          facility={searchParams?.facility}
          businessDayHolidays={businessDayHolidays}
          additionalSettingsData={groupedObj}
          handleDateChange={(state: any) => {
            setsearchParams({
              ...state,
            })
          }}
          activeStartDate={
            new Date(searchParams?.year, searchParams?.month - 1, 1)
          }
          handleEditOnClick={handleEditOnClick}
          hasEditAccess={hasEditAccess}
        />
      </PageCard>
    </Wrapper>
  )
}

export { AdditionalItemFacilityForm }
